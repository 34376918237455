import React from 'react'
import '../estilos-PagDoProd/module.description.css'
import Papel from '../imagens-PagDoProd/papelzinho.svg'
import UpArrow from '../imagens-PagDoProd/setapracima.svg'

export default function(){

    const testando = () => {
        var content = document.getElementsByClassName("wrapper-down-description");
        if (content.style.display === "block") {
            content.style.display = "none";
        } 
        else {
            content.style.display = "block";
            /* alert("oii"); */
        }
    }

    return(
        <div className='wrapper-description'>
            <div className='wrapper-up-description'>
                <div className='wrapper-up-left-description'>
                    <img alt="descrição do produto" src={Papel}></img>
                    <h2>Descrição do Produto</h2>
                </div>
                
                <button type="button" className='botaooo' onClick={testando}><img src={UpArrow}></img></button>
            </div>

            <div className='wrapper-down-description'>
                <div>
                    <h3 className='wd-title-description'>Nome do Produto</h3> {/* dinâmico */}
                    <p className='wd-content-description'>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ultrices felis, blandit vestibulum nunc, ante ipsum aliquet amet. Donec enim vulputate suspendisse habitant massa. Quam porta bibendum senectus nibh lorem tristique bibendum integer. Sed tempor venenatis habitant aliquam commodo. Nisl leo nunc arcu ut. Euismod tempus leo diam pretium etiam mattis sit. Nisl duis diam posuere pretium at magna nibh eget. Tempor vel at eu sit ut.
                    </p>
                    <p className='wd-content2-description'>
                        Ornare tortor in massa, ultrices elit massa purus phasellus. Consectetur vitae massa placerat praesent eget pellentesque nisl tempus pharetra. Praesent sed id orci lobortis posuere lorem. Aliquam nec, elit id facilisis. Quam pretium sociis blandit viverra curabitur fringilla platea diam. Blandit nunc mollis amet erat. Sed lectus neque, dictumst ut pretium risus vestibulum tempor velit. Sit phasellus fermentum cras commodo cursus ut lacus, malesuada amet. Leo turpis vel dictum sollicitudin eleifend pharetra elit morbi magna.
                    </p>
                </div>

                <div>
                    <h3 className='wd-title-description'>Características</h3>
                    <p className='wd-content-description'>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ultrices felis, blandit vestibulum nunc, ante ipsum aliquet amet. Donec enim vulputate suspendisse habitant massa. Quam porta bibendum senectus nibh lorem tristique bibendum integer. Sed tempor venenatis habitant aliquam commodo. Nisl leo nunc arcu ut. Euismod tempus leo diam pretium etiam mattis sit. Nisl duis diam posuere pretium at magna nibh eget. Tempor vel at eu sit ut.
                    </p>
                    <p className='wd-content2-description'>
                        Ornare tortor in massa, ultrices elit massa purus phasellus. Consectetur vitae massa placerat praesent eget pellentesque nisl tempus pharetra. Praesent sed id orci lobortis posuere lorem. Aliquam nec, elit id facilisis. Quam pretium sociis blandit viverra curabitur fringilla platea diam. Blandit nunc mollis amet erat. Sed lectus neque, dictumst ut pretium risus vestibulum tempor velit. Sit phasellus fermentum cras commodo cursus ut lacus, malesuada amet. Leo turpis vel dictum sollicitudin eleifend pharetra elit morbi magna.
                    </p>
                </div>
            </div>
        </div>
    )
}