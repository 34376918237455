import React from 'react'
import Styles from './module.menu.css';
import logo from './imagens-menu/Vector5.png'
import InputBusca from './subpartes-menu/input-busca'
import Bloco from './subpartes-menu/perfil'
import Carrinho from './subpartes-menu/carrinho'

import { useNavigate} from 'react-router-dom';

export default function(){
    
    const navigate = useNavigate();
    
    return(
        <nav className="menu">
            <div className="menu-logo" onClick = {() => navigate('/')} >
                <img className="menu-logoImg" src={logo}></img>
        
                <div className="menu-logo-text">
                    <h2>Confecções Zum Zum </h2>
                    <h3 className="texto-colorido">CAPAS / DECORAÇÕES / CAMA </h3>
                </div>
            </div>
            
            <div className="menu-user">
                <div className="menu-user-left">
                </div>
        
                    <div className="menu-user-right">
                    {/* <Bloco/> */}

                </div>
                </div>
        </nav>
    )
}

