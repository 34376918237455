import React from 'react'
import '../estilos-sobreNos/module.sobreNos2.css'


export default function(){
    return(
        
        <div className="sn2-container-sobre2">
            <h1>Missão / Visão / Valores</h1>
            <div className='sn2-numeros-empresa-wrapper'>

                <div className='sn2-numeros-empresa'>
                    <h2>Missão</h2>
                    <h3>Oferecer conforto, beleza e praticidade para o lar com produtos para cama, mesa e decoração que surpreendam nossos clientes e deixem os lares ainda mais bonitos e encantadores.</h3>
                </div>

                <div className='sn2-numeros-empresa'>
                    <h2>Visão</h2>
                    <h3>Ser referência no atacado em todo o Brasil e no varejo na cidade de Capivari, nos segmentos de enxovais para o lar e capas para sofá, gerando satisfação e fidelização dos nossos clientes.</h3>
                </div>

                <div className='sn2-numeros-empresa2'>
                    <h2>Valores</h2>
                    <h3>A Confecções Zum Zum preza pelo trabalho em equipe e pelo respeito, trabalhando com comprometimento e com prazer.</h3>
                </div>
            </div>

        </div>

    )
}