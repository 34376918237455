import React, { useEffect,useState, useRef } from 'react'
import './home.css'
import {Link} from 'react-router-dom'

export default function(){
    return(
        <div>
            
            <div className="containerhome">
            <div className="esquerdahome">
                <h1 className="titulosss" style={{fontSize:"7vw"}}>Confecções Zum Zum</h1>
                <br/>
                <div className="butaohome">
                <Link to='/catalogo'>
                    <button type='button' style={{fontSize:"2.7vw"}}>Veja o catálogo</button>
                </Link>
                <br/>
                <Link to='/sobrenos'>
                <button type='button' style={{fontSize:"2.7vw"}}>Sobre nós</button>
                </Link>
                </div>
            </div>
            <div className="direitahome">
                <img src="/midia/homefoto.jpg"/>
            </div>
            </div>
            <br/>
            <br/>
            
        </div>
    )
}