import React, { useState } from 'react'
import './module.PagDoProd.css'
import Footer from '../footer/footer'
import Menu from '../menu/menu'
import Carrossel_Imagens from './subpartes-PagDoProd/carrossel-imagens'
import Tamanhos from './subpartes-PagDoProd/tamanhos'
import Description from './subpartes-PagDoProd/description'
import camisetona from './imagens-PagDoProd/camiseta_imagem.svg'


export default function(){
    
    // ISSO AQUI É PROVISORIO PARA TESTES DE ADICIONAR ESSE PRODUTO NO CARRINHO
    const [produto, setProduto] = useState({id_produto: 1, nome: "Camisa Básica", tipo: "camisa", descricao: "", fav: true, cor: "verde", tamanho:"G",temtamanho:true, temcor:true, quantidade: 1, produto_user: 2, imgCamisa: camisetona});

    return(
        <div className='wrapper-pdp'>
            <Menu/>

            {/* Acho que esses textos precisam ser dinâmicos de alguma forma */}
            <div className='text-pdp'>
                <h2>Camisas/Times/Palmeiras</h2>
                <h1>Camisa 1 Palmeiras</h1>
            </div>

            <div className='pt1-wrapper-pdp'>
                <Carrossel_Imagens/>
                <Tamanhos/>
            </div>

            <Description/>
            <Description/>

            <Footer/>
        </div>
    )
}