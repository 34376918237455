import React from 'react'
import './module.sobreNos.css'
import Formulario from '../form/form'
import SobreOne from './subpartes-sobreNos/sobreNos1'
import SobreTwo from './subpartes-sobreNos/sobreNos2'
import SobreThree from './subpartes-sobreNos/sobreNos3'
import SobreFour from './subpartes-sobreNos/sobreNos4'
import Footer from '../footer/footer'
import Menu from '../menu/menu'

export default function(){
    return(
        
/*         <React.Fragment>
        <SobreTo/>
        <NmrEmpresa/>
        </React.Fragment> */

        <div className='sn-wrapper' style={{height:"100%"}}> 
            <Menu/>
            <div className='sn-wrapper-2'>
                <div classname='sn-item'>
                <SobreOne/>
                </div>
                <div classname='sn-item'>
                <SobreTwo/>
                
                </div>
            </div>
            
        </div>

    )
}



