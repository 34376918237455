
import './index.css'
import ProdutoCarrinho from '../ProdutoCarrinho/index.js'
import camisaImage from '../../assets/camiseta_imagem.svg'
import produtosCarrinho from '../../constantes/dadosCarrinho'
import './index.css'

export default function Carrinho(){
        
    return (
        <div>
            <div className="carrinhoTitle">
            <h1 className="carrinhoTitle">Revise seu carrinho</h1>
            </div>
            {produtosCarrinho.map( (produto) => {
                return (<ProdutoCarrinho produto={produto} />);
                /*
                * Esse código acima é equivalente ao abaixo. É mais intuitivo entender o código abaixo, mas
                * fica muito verboso.
                * return (<ProdutoCarrinho nome = {produto.name} tipo = {produto.tipo}
                *     cor = {produto.cor} tamanho = {produto.tamanho} quantidade = {produto.quantidade}/>);
                **/
            } )}
        </div>
    );


}
