import React, { useEffect,useState, useRef } from 'react'
import './slider.css'
import Error from './error'
import Button from '../catalogo/button'


export default function(){
    const [data,setData] = useState([]);
    const carousel = useRef(null);

    useEffect( ()=>{
        // http://localhost:4040/api/products   CONEXÃO COM O BACK
        fetch('http://localhost:3000/midia/dados.json').then((response)=> response.json()).then(setData)

    },[]  )
    return (
        
<div className='MargemDiv'>
<div className="catalogotitulo">
                <h1>Catálogo</h1>
            </div>
            <br/>
            <br/>
<div className='favTitle' style={{alignItems:"center"}}>
<h1>
    Capas de sofá
</h1>
</div>
<div className='container'>
<div className="catalogo-container catalogo-preview">


<div className="catalogo-itens" ref={carousel}>
    
    {data.map( (item) => {
        const {id_produto,nome,tipo,descricao} = item; 
        /*
        return (
           
    <div className="item-catalogo">
         <div className="image-catalogo" key={id_produto}>
             <img src="/midia/camiseta2.png" alt="produto"  />
        </div>
        <div className="info-catalogo">
            <span className="name" >{nome}  </span>
            <span className="price" >{descricao}</span>

        </div>
        
        
        

 </div>


 
 
 
  );
  */
                }  )}


      


<div className="item-catalogo">
<div className="containerr">
        <div className="wpp">
            <a href="https://wa.me/5519998960042?text=Olá%20gostaria%20de%20comprar%20uma%20capa%20de%20sofá%20Royale%20Gold" target="_blank">
                <img src="/midia/wpplogofoto.png" alt="whatsapp" style={{width:"10vw"}}/>
            </a>
        </div>
    <div className="image-catalogo">
    <a className="acatalogo" href="https://wa.me/5519998960042?text=Olá%20gostaria%20de%20comprar%20uma%20capa%20de%20sofá%20Royale%20Gold" target="_blank">
        <img src="/midia/sofaroyalegold.png" alt="produto"   />
        </a>
    </div>
    </div>
    <div className="info-catalogo">
        <span className="name" >Royale Gold</span>
        <span className="price" >25 elásticos de 35mm costurados c/ 5 agulhas, malha gel 100% poliéster, com borracha de fixação</span>
    </div>
</div>

<div className="item-catalogo">
<div className="containerr">
    <div className="wpp">
    <a href="https://wa.me/5519998960042?text=Olá%20gostaria%20de%20comprar%20uma%20capa%20de%20sofá%20Royale%20Gold%20estampada" target="_blank">
        <img src="/midia/wpplogofoto.png" alt="whatsapp" style={{width:"10vw"}}/>
    </a>
    </div>
    <div className="image-catalogo">
        <a className="acatalogo" href="https://wa.me/5519998960042?text=Olá%20gostaria%20de%20comprar%20uma%20capa%20de%20sofá%20Royale%20Gold%20estampada" target="_blank">
            <img src="/midia/sofaroyalegoldestampada.png" alt="produto"   />
        </a>
    </div>
</div>
    <div className="info-catalogo">
        <span className="name"  >Royale Gold (estampada)</span>
        <span className="price" >25 elásticos de 35mm costurados c/ 4 agulhas, malha gel 100% poliéster, com borracha de fixação, detalhes do assento c/ tecido estampado</span>
    </div>
</div>

<div className="item-catalogo">
<div className="containerr">
        <div className="wpp">
            <a href="https://wa.me/5519998960042?text=Olá%20gostaria%20de%20comprar%20uma%20capa%20de%20sofá%20Florença" target="_blank">
            <img src="/midia/wpplogofoto.png" alt="whatsapp" style={{width:"10vw"}}/>
            </a>
        </div>
    <div className="image-catalogo">
    <a className="acatalogo" href="https://wa.me/5519998960042?text=Olá%20gostaria%20de%20comprar%20uma%20capa%20de%20sofá%20Florença" target="_blank">
        <img src="/midia/sofaflorenca.png" alt="produto"  />
        </a>
    </div>
    </div>
    <div className="info-catalogo">
        <span className="name" >Florença</span>
        <span className="price" >21 elásticos de 20mm costurados c/ 2 agulhas, malha helanca trabalhada 100% poliéster, com borracha de fixação</span>
    </div>
</div>

<div className="item-catalogo">
<div className="containerr">
        <div className="wpp">
       <a href="https://wa.me/5519998960042?text=Olá%20gostaria%20de%20comprar%20uma%20capa%20de%20sofá%20Premiere" target="_blank">
            <img src="/midia/wpplogofoto.png" alt="whatsapp" style={{width:"10vw"}}/>
        </a>
        </div>
    <div className="image-catalogo">
    <a className="acatalogo" href="https://wa.me/5519998960042?text=Olá%20gostaria%20de%20comprar%20uma%20capa%20de%20sofá%20Premiere" target="_blank">
        <img src="/midia/sofapremiere.png" alt="produto"  />
        </a>
    </div>
    </div>
    <div className="info-catalogo">
        <span className="name" >Premiere</span>
        <span className="price" >1 elástico de 25mm na barra, malha gel 100% poliéster</span>
    </div>
</div>

 
 
 
             

 </div>

</div>
</div>




<div className='favTitle' style={{alignItems:"center"}}>
<h1>
    Capa para almofadas
</h1>
</div>
<div className='container'>
<div className="catalogo-container catalogo-preview">


<div className="catalogo-itens" ref={carousel}>
    
    {data.map( (item) => {
        const {id_produto,nome,tipo,descricao} = item; 
                }  )}


      


<div className="item-catalogo">
<div className="containerr">
        <div className="wpp">
        <a href="https://wa.me/5519998960042?text=Olá%20gostaria%20de%20comprar%20uma%20capa%20para%20almofada%20gorgurinho%20com%20franja" target="_blank">
            <img src="/midia/wpplogofoto.png" alt="whatsapp" style={{width:"10vw"}}/>
            </a>
        </div>
    <div className="image-catalogo">
    <a className="acatalogo" href="https://wa.me/5519998960042?text=Olá%20gostaria%20de%20comprar%20uma%20capa%20para%20almofada%20gorgurinho%20com%20franja" target="_blank">
        <img src="/midia/capatravesseiro1.png" alt="produto"  />
        </a>
    </div>
    </div>
    <div className="info-catalogo">
        <span className="name"  >Capa almofada gorgurinho c/ franja</span>
        <span className="price" >Tamanho 45cm x 45cm, fechamento em zíper</span>
    </div>
</div>

<div className="item-catalogo">
<div className="containerr">
        <div className="wpp">
        <a href="https://wa.me/5519998960042?text=Olá%20gostaria%20de%20comprar%20uma%20capa%20para%20almofada%20gorgurinho%20com%20moldura%20do%20mesmo%20tecido" target="_blank">
            <img src="/midia/wpplogofoto.png" alt="whatsapp" style={{width:"10vw"}}/>
            </a>
        </div>
    <div className="image-catalogo">
    <a className="acatalogo" href="https://wa.me/5519998960042?text=Olá%20gostaria%20de%20comprar%20uma%20capa%20para%20almofada%20gorgurinho%20com%20moldura%20do%20mesmo%20tecido" target="_blank">
        <img src="/midia/capatravesseiro2.png" alt="produto"  />
        </a>
    </div>
    </div>
    <div className="info-catalogo">
        <span className="name" >Capa almofada gorgurinho c/ moldura do mesmo tecido</span>
        <span className="price" >Tamanho 45cm x 45cm, fechamento em zíper</span>
    </div>
</div>

<div className="item-catalogo">
<div className="containerr">
        <div className="wpp">
        <a href="https://wa.me/5519998960042?text=Olá%20gostaria%20de%20comprar%20uma%20capa%20para%20almofada%20de%20tecido%20Native" target="_blank">
            <img src="/midia/wpplogofoto.png" alt="whatsapp" style={{width:"10vw"}}/>
            </a>
        </div>
    <div className="image-catalogo">
    <a className="acatalogo" href="https://wa.me/5519998960042?text=Olá%20gostaria%20de%20comprar%20uma%20capa%20para%20almofada%20de%20tecido%20Native" target="_blank">
        <img src="/midia/capatravesseiro3.png" alt="produto"  />
        </a>
    </div>
    </div>
    <div className="info-catalogo">
        <span className="name" >Capa almofada tecido Native</span>
        <span className="price" >Tecido trabalhado, tamanho 45cm x 45cm, fechamento em zíper</span>
    </div>
</div>

<div className="item-catalogo">
<div className="containerr">
        <div className="wpp">
        <a href="https://wa.me/5519998960042?text=Olá%20gostaria%20de%20comprar%20uma%20capa%20para%20almofada%20de%20tecido%20Savage" target="_blank">
            <img src="/midia/wpplogofoto.png" alt="whatsapp" style={{width:"10vw"}}/>
            </a>
        </div>
    <div className="image-catalogo">
    <a className="acatalogo" href="https://wa.me/5519998960042?text=Olá%20gostaria%20de%20comprar%20uma%20capa%20para%20almofada%20de%20tecido%20Savage" target="_blank">
        <img src="/midia/capatravesseiro4.png" alt="produto"  />
        </a>
    </div>
    </div>
    <div className="info-catalogo">
        <span className="name" >Capa almofada tecido Savage</span>
        <span className="price" >Tecido trabalhado, tamanho 45cm x 45cm, fechamento em zíper</span>
    </div>
</div>

 
 
 
             

 </div>

</div>
</div>
<div className='favTitle' style={{alignItems:"center"}}>
<h1>
    Outros
</h1>
</div>
<div className='container'>
<div className="catalogo-container catalogo-preview">


<div className="catalogo-itens" ref={carousel}>
    
    {data.map( (item) => {
        const {id_produto,nome,tipo,descricao} = item; 
                }  )}


      


<div className="item-catalogo">
<div className="containerr">
        <div className="wpp">
        <a href="https://wa.me/5519998960042?text=Olá%20gostaria%20de%20comprar%20um%20protetor%20para%20sofá" target="_blank">
            <img src="/midia/wpplogofoto.png" alt="whatsapp" style={{width:"10vw"}}/>
            </a>
        </div>
    <div className="image-catalogo">
    <a className="acatalogo" href="https://wa.me/5519998960042?text=Olá%20gostaria%20de%20comprar%20um%20protetor%20para%20sofá" target="_blank">
        <img src="/midia/capasofa.png" alt="produto" />
        </a>
    </div>
    </div>
    <div className="info-catalogo">
        <span className="name">Protetor para sofá</span>
        <span className="price" >Acabamento em viés, disponível em 4 estampas, kit para sofá 2 e 3 lugares</span>
    </div>
</div>

<div className="item-catalogo">
<div className="containerr">
        <div className="wpp">
        <a href="https://wa.me/5519998960042?text=Olá%20gostaria%20de%20comprar%20uma%20capa%20para%20colchão" target="_blank">
            <img src="/midia/wpplogofoto.png" alt="whatsapp" style={{width:"10vw"}}/>
            </a>
        </div>
    <div className="image-catalogo">
    <a className="acatalogo" href="https://wa.me/5519998960042?text=Olá%20gostaria%20de%20comprar%20uma%20capa%20para%20colchão" target="_blank">
        <img src="/midia/capacolchao.png" alt="produto"  />
        </a>
    </div>
    </div>
    <div className="info-catalogo">
        <span className="name" >Capa colchão</span>
        <span className="price" >Fechamento em zíper, tecido 67% poliéster 33% algodão</span>
    </div>
</div>

<div className="item-catalogo">
<div className="containerr">
        <div className="wpp">
        <a href="https://wa.me/5519998960042?text=Olá%20gostaria%20de%20comprar%20um%20protetor%20para%20colchão" target="_blank">
            <img src="/midia/wpplogofoto.png" alt="whatsapp" style={{width:"10vw"}}/>
            </a>
        </div>
    <div className="image-catalogo">
    <a className="acatalogoo" href="https://wa.me/5519998960042?text=Olá%20gostaria%20de%20comprar%20um%20protetor%20para%20colchão" target="_blank">
        <img src="/midia/projetorcolchao.jpg" alt="produto" />
    </a>
    <a className="acatalogo2" href="https://wa.me/5519998960042?text=Olá%20gostaria%20de%20comprar%20um%20protetor%20para%20colchão" target="_blank">
        <img src="/midia/protetorcolchao2.jpg" alt="produto" />
    </a>
    </div>
    </div>
    <div className="info-catalogo">
        <span className="name" >Protetor impermeável para colchão</span>
        <span className="price" >Acabamento com elástico, tecido malha 100% poliéster c/ revestimento poliuretano</span>
    </div>
</div>

<div className="item-catalogo">
    <div className="containerr">
        <div className="wpp">
        <a href="https://wa.me/5519998960042?text=Olá%20gostaria%20de%20comprar%20uma%20toalha%20de%20mesa" target="_blank">
            <img src="/midia/wpplogofoto.png" alt="whatsapp" style={{width:"10vw"}}/>
            </a>
        </div>
        <div className="image-catalogo">
        <a className="acatalogoo" href="https://wa.me/5519998960042?text=Olá%20gostaria%20de%20comprar%20uma%20toalha%20de%20mesa" target="_blank">
            <img src="/midia/toalhasmesa.jpg" alt="produto"  />
            </a>
            <a className="acatalogo2" href="https://wa.me/5519998960042?text=Olá%20gostaria%20de%20comprar%20uma%20toalha%20de%20mesa" target="_blank">
            <img src="/midia/toalhamesa2.jpg" alt="produto"  />
            </a>
        </div>
    </div>
    <div className="info-catalogo">
        <span className="name" >Toalhas de mesa</span>
        <span className="price" >Tecido 100% poliéster</span>
    </div>
</div>

<div className="item-catalogo">
<div className="containerr">
        <div className="wpp">
        <a href="https://wa.me/5519998960042?text=Olá%20gostaria%20de%20comprar%20um%20mosquiteiro" target="_blank">
            <img src="/midia/wpplogofoto.png" alt="whatsapp" style={{width:"10vw"}}/>
            </a>
        </div>
    <div className="image-catalogo">
        <a className="acatalogoo" href="https://wa.me/5519998960042?text=Olá%20gostaria%20de%20comprar%20um%20mosquiteiro" target="_blank">
        <img src="/midia/mosquiteiro.png" alt="produto" />
        </a>
        <a className="acatalogo2" href="https://wa.me/5519998960042?text=Olá%20gostaria%20de%20comprar%20um%20mosquiteiro" target="_blank">
        <img src="/midia/mosquiteiro2.jpg" alt="produto" />
        </a>
    </div>
    </div>
    <div className="info-catalogo">
        <span className="name" 
        >Mosquiteiro</span>
        <span className="price" >Filó composição 100% poliamida, mosquiteiro de teto já vem com ganho e bucha para fixação</span>
    </div>
</div>

 
 
 
             

 </div>

</div>
</div>
</div>









)




}