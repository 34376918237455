import React from 'react'
import Carrinho from '../../componentes/Carrinho/index.js'
import Footer from '../../componentes/footer/footer.js'
import Menu from '../../componentes/menu/menu.js'

export default function(){
    return(
        <div className='wrapper-pdp'>
            <Menu/>
            <Carrinho/>
            <Footer/>
        </div>
    )
}