
import './index.css'
import imgDelete from '../../assets/delete.svg'
import imgMinus from '../../assets/minus.svg'
import imgPlus from '../../assets/plus.svg'
import { maxHeaderSize } from 'http';
import React, { useState } from 'react';

// {produto} é uma desestruturação! 
export default function ProdutoCarrinho({produto}){

    const [produtoqtdd, setQtdd] = React.useState(1);

    function handleClickSoma() {
    setQtdd(produtoqtdd + 1);
  }
  function handleClickMenos() {
    if(produtoqtdd>0){
    setQtdd(produtoqtdd - 1);
    }
  }

    return (

        <div className = "produto-carrinho" key = {produto.id_produto}>

            <h1>{produto.name}</h1>

            <img src = {produto.imgCamisa}/>
                <div className = "descricao-produto-carrinho">
                <h1>{produto.nome}</h1>
                <p>{"Tamanho: " + produto.tamanho }</p>
                <p>{"Cor: " + produto.cor }</p>
             </div>
            <div className = "action-buttons-produto-carrinho">
                <button style={{border:"none", backgroundColor:"white"}} onClick={handleClickMenos}>
                <img src = {imgMinus}/>
                </button>
                <p>{produtoqtdd}</p>
                <button style={{border:"none", backgroundColor:"white"}} onClick={handleClickSoma}>
                <img src = {imgPlus}/>
                </button>
            </div>
            <div className = "delete-produto-carrinho">
                <img src = {imgDelete}/>
            </div>
        </div>
    
    );

}
