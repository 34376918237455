
import { useState } from 'react';
import imagemCamisaCarrinho from '../../src/assets/camiseta_imagem.svg'

const produtosCarrinho= [
    {id_produto: 1, nome: "Camisa Básica", tipo: "camisa", descricao: "", fav: true, cor: "verde", tamanho:"G",temtamanho:true, temcor:true, quantidade: 1, produto_user: 2, imgCamisa: imagemCamisaCarrinho},
    {id_produto: 2, nome: "Camisa Básica", tipo: "camisa", descricao: "", fav: true, cor: "verde", tamanho:"G",temtamanho:true, temcor:true, quantidade: 1, produto_user: 2, imgCamisa: imagemCamisaCarrinho},
    {id_produto: 3, nome: "Camisa Básica", tipo: "camisa", descricao: "", fav: true, cor: "verde", tamanho:"G",temtamanho:true, temcor:true, quantidade: 1, produto_user: 2, imgCamisa: imagemCamisaCarrinho},
    {id_produto: 4, nome: "Camisa Básica", tipo: "camisa", descricao: "", fav: true, cor: "verde", tamanho:"G",temtamanho:true, temcor:true, quantidade: 1, produto_user: 2, imgCamisa: imagemCamisaCarrinho},
    {id_produto: 5, nome: "Camisa Básica", tipo: "camisa", descricao: "", fav: true, cor: "verde", tamanho:"G",temtamanho:true, temcor:true, quantidade: 1, produto_user: 2, imgCamisa: imagemCamisaCarrinho},
];

export default produtosCarrinho;

/**
 * No futuro essa variável const produtosCarrinho deve se tornar um State.
 * const [produtosCarrinho, setProdutosCarrinhos ] = useState(); 
 * setProdutosCarrinhos( produtosCarrinho => [...produtosCarrinho, produtoNovoCarrinho] )
 */
