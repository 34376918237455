import React from 'react'
import Menu from '../../componentes/menu/menu'
import Catalogo from '../../componentes/catalogo/catalogo'
import Footer from '../../componentes/footer/footer'
import SliderNovidades from '../../componentes/slider/sliderNovidades.js'


export default function(){
    return (
        <>
           <Menu/> 
           {
                <SliderNovidades/>
            //<Catalogo/> 
            }

            
             
            <Footer/> 
        </>
         
    )
}

