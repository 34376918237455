import React from 'react'
import SobreNos from '../../componentes/sobreNos/sobreNos.js'
import Footer from '../../componentes/footer/footer'


export default function(){
    return(
        <>
        <SobreNos/>
         <Footer/> 
        </>
    )

}