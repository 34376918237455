import React from 'react'
import './module.footer.css'
import sacola from '../menu/imagens-menu/Vector5.png'
import whats from '../sobreNos/imagens-sobreNos/footer-whats.svg'
import insta from '../sobreNos/imagens-sobreNos/footer-insta.svg'
import facebook from '../sobreNos/imagens-sobreNos/footer-facebook.svg'
import email from '../sobreNos/imagens-sobreNos/footer-email.svg'
import telefone from '../sobreNos/imagens-sobreNos/footer-telefone.svg'
import mapa from '../sobreNos/imagens-sobreNos/footer-mapa.svg'
import {Link} from 'react-router-dom'


export default function(){
    return(
        
        <div className="container-sobre-footer2">

            <div className='footer'>
                <div className='footer-left'> 
                    <div className='footer-left-top'>
                        <img className='sacolaa' src={sacola}/>
                        <div className='footer-left-text'>
                            <h2>Confecções Zum Zum</h2>
                            <p>CAPAS / DECORAÇÕES / CAMA</p>
                        </div>
                    </div>

                    <p>Nossas redes sociais :</p>

                    <div className='footer-left-imagens'>
                        <a href="https://wa.me/5519998960042" target="_blank">
                            <img className='cor-marrom' src={whats}/>
                        </a>
                        <a href="https://www.instagram.com/zumzumenxovais/" target="_blank">
                            <img className='cor-marrom' src={insta}/>
                        </a>
                    </div>
                </div>

                <div className='footer-middle'>
                    <p>Contato:</p>

                    <div className='footer-contato'>
                        <img className='img1' src={email}/>
                        <p>zumzum@confeccoeszumzum.com.br</p>
                    </div>

                    <div className='footer-contato'>
                        <img className='img2' src={telefone}/>
                        <p>(19) 9 9896-0042</p>
                    </div>

                    <div className='footer-contato'>
                        <img className='img3' src={mapa}/>
                        <p>Endereço: Rua Santa Cruz, 710 - Centro - Capivari/SP</p>
                    </div>
                </div>

                <div className='footer-right'>
                <Link to='/sobrenos'>
                    <p>Sobre Nós</p>
                    </Link>
                </div>

            </div>

            <div className='footer-end'>
                <hr className='footer-line'/>
                <br />

                <div className='footer-end-text'>
                    Confecções Zum Zum - Capas / Decorações / Cama
                </div>
            </div>

        </div>
    )
}