import React from 'react'
import '../estilos-PagDoProd/module.carrossel-imagens.css'
import Camisetona from '../imagens-PagDoProd/camiseta_imagem.svg'
import Compartilhar from '../imagens-PagDoProd/compartilhar.svg'

export default function(){
    return(
        <div className='wrapper-c-i'>
            <img alt="botão de compartilhar produto" className='botao-share' src={Compartilhar}></img>

            <div className='wrapper-main-c-i'>
                <div className='big-photo-c-i'>
                    <button className="botao-voltar botao-c-i" aria-label="Voltar" type="button" aria-disabled="false"> &lt; </button>
                    <div>
                        {/* style="width: 100%; height: auto; transform: translate(0px, 0px);" */}
                        <img alt="descrição dinâmica do produto" class="imagem-exibida" src={Camisetona}></img>
                    </div>
                    <button className="botao-proximo botao-c-i" aria-label="Próximo" type="button" aria-disabled="true"> &gt; </button>
                
                </div>
                <div className='miniFotos-wrapper-c-i'>
                    <ul className='miniFotos-c-i'>
                        <li>
                            <div>
                                {/* <img alt="descrição dinâmica do produto" className='imagens-mini mini1-c-i' src="#"> </img> */}
                            </div>
                        </li>
                        <li>
                            <div>
                                {/* <img alt="descrição dinâmica do produto" className='imagens-mini mini2-c-i' src="#"> </img> */}
                            </div>
                        </li>
                        <li>
                            <div>
                                {/* <img alt="descrição dinâmica do produto" className='imagens-mini mini3-c-i' src="#"> </img> */}
                            </div>
                        </li>
                        <li>
                            <div>
                                {/* <img alt="descrição dinâmica do produto" className='imagens-mini mini4-c-i' src="#"> </img> */}
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

        </div>
    )
}