import React from 'react'
import '../estilos-sobreNos/module.sobreNos1.css'
import rightSideImage from '../imagens-sobreNos/sobreNos_RightSide.svg'


export default function(){
    return(
        
        <div className="container-sobre">

            <div className="sobreRight">
                <div className="imagem">
                    <img src="/midia/fabrica.jpg" style={{borderRadius:"15px"}}/>        
                </div>
            </div>

            <div className="sobreLeft">
                <div className="sobreTexto">
                <h1>Sobre Nós</h1>
                    <p>
                    Fundada em 1992, a Confecções Zum Zum começou produzindo mosquiteiros infantis, por isso o nome "Zum Zum". Alguns anos depois, o senhor Agnello Rinaldi migrou para o ramo de capas para colchão, porém decidiu manter o nome por ser uma marca já consolidada. Atualmente, além das capas para colchão, confeccionamos capas para sofá e todo ramo de cama, mesa e decoração. Cada produto aqui produzido reflete nosso compromisso com nossos parceiros e clientes.
                    </p>
                </div>
            </div>

        </div>

    )
}