import React from 'react'
import ReactDOM from 'react-dom'
import App from './app'
import Pagina_do_Produto from './componentes/PagDoProd/PagDoProd'
import PaginaCarrinho from './pages/PaginaCarrinho/index.js'
import SobreNos from './pages/SobreNos/index.js'
import Catalogo from './pages/Catalogo/index.js'
import Main from './pages/Main/index.js'

/* 
* Utilizando BrowserRouter do REACT para redirecionar entre páginas.
**/
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'

ReactDOM.render(
    <Router>
        <Routes>
            <Route path = {'/paginaDoProduto'} element = {<Pagina_do_Produto/>} />
            <Route path = {'/paginaDoCarrinho'} element = {<PaginaCarrinho/>}/>
            <Route path = {'/sobreNos'} element = {<SobreNos/>}/>
            <Route path = {'/catalogo'} element = {<Catalogo/>}/>
            <Route path = {'/'} element = {<Main/>}/>
        </Routes>
    </Router>

,document.getElementById('root'))
