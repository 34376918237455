import React from 'react'
import Menu from '../../componentes/menu/menu'
import SliderTipo from '../../componentes/slider/tipo/sliderTipo'
import Sliderfav from '../../componentes/slider/slider'
import Catalogo from '../../componentes/catalogo/preview/index'
import Footer from '../../componentes/footer2/footer'
import SliderNovidades from '../../componentes/slider/sliderNovidades.js'
import Home from '../../componentes/home/home.js'


export default function(){
    return (
        <>
            <Menu/> 
        
            {
                <Home/>
            }

            
           
            {
            <Footer/> 
            }

        </>
         
    )
}

