import React from 'react'
import '../estilos-PagDoProd/module.tamanhos.css'
import Camisetona from '../imagens-PagDoProd/camiseta_imagem.svg'
import Compartilhar from '../imagens-PagDoProd/compartilhar.svg'

export default function(){
    return(
        <div className='wrapper-tamanhos'>

            <h2 className='titulo-tamanhos'>Escolha o tamanho:</h2>

            <div className='miniwrapper-tamanhos'>
                <ul className='lista-tamanhos'>
                    <li className='tamanhos-de-cima tdc1'><div>PP</div></li>
                    <li><div>P</div></li>
                    <li className='tamanhos-de-cima tdc2'><div>M</div></li>
                    <li><div>G</div></li>
                    <li className='tamanhos-de-cima tdc3'><div>GG</div></li>
                    <li><div>XG</div></li>
                </ul>

                <button className="botao-tamanhos" aria-label="Adicionar ao Carrinho" type="button" aria-disabled="false"> Adicionar ao Carrinho </button>

                <div className='ou-tamanhos'>
                    <hr></hr>
                    <p>ou</p>
                    <hr></hr>
                </div>

                <button className="botao-tamanhos" aria-label="Gerar o orçamento" type="button" aria-disabled="false"> Gerar o orçamento </button>
        
                <a className='voltar-tamanhos' href='#'>Voltar ao Catálogo</a>
            </div>

        </div>
    )
}